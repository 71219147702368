import React from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material'
import { navigate } from 'gatsby'
import { buildImageLink, formatPrice } from '../../helpers'
import { Price, Promotion, Sold, TextContainer } from './Styled'
import { routePaths } from '../../constants/routes'
import { ImagesContainer } from '../common/Styled'
import { Product } from '../../helpers/types'

const ProductCard = (props: Product) => {
  const { strapi_id, title, price, images, promotion, sold } = props

  return (
    <Card
      onClick={() =>
        navigate(routePaths.product.replace(':id', strapi_id.toString()))
      }
      sx={{
        width: '350px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <CardActionArea>
        <ImagesContainer hasSecondPicture={!!images?.[1]}>
          {images?.[0] && (
            <CardMedia
              component="img"
              className="first-picture"
              alt={images[0].alternativeText}
              height="300"
              image={buildImageLink(images[0].url)}
              title={images[0].alternativeText}
            />
          )}
          {images?.[1] && (
            <CardMedia
              component="img"
              className="second-picture"
              alt={images[1].alternativeText}
              height="300"
              image={buildImageLink(images[1].url)}
              title={images[1].alternativeText}
            />
          )}
        </ImagesContainer>

        <CardContent>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <TextContainer>
            <Price
              align="center"
              variant="body1"
              color="textSecondary"
              hasPromotion={!!promotion}
              isSold={sold}
            >
              {formatPrice(price)}
            </Price>
            {promotion && (
              <Promotion
                align="center"
                variant="body1"
                color="textSecondary"
                isSold={sold}
              >
                {formatPrice(promotion)}
              </Promotion>
            )}
            {sold && (
              <Sold color="primary" align="center" variant="body1">
                Vendu !
              </Sold>
            )}
          </TextContainer>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ProductCard
