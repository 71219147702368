import { styled } from '@mui/material/styles'

export const ProductsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1110px;
  margin: auto;
`

export const ProductsListContainer = styled('div')(({ theme }) => ({
  margin: '25px 0',
  display: 'flex',
  gap: '30px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center'
  }
}))

export const PaginationContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

export const FiltersContainer = styled('div')`
  width: 250px;
  margin: 0 15px;
  position: sticky;
  top: 75px;
  @media (max-width: 600px) {
    position: inherit;
    display: flex;
    flex-direction: column-reverse;
    width: auto;
    row-gap: 10px;
    margin: 0;
    align-items: center;
  }
`

export const CheckboxContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const FilterPriceContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const SortContainer = styled('div')`
  @media (max-width: 600px) {
    width: 100%;
    h2 {
      display: none;
    }
  }
`

export const FilterContainer = styled('div')`
  #mobile-filter-btn {
    display: none;
  }

  @media (max-width: 600px) {
    align-items: center;
    #mobile-filter-btn {
      display: flex;
    }
    #filter-title,
    #first-divider {
      display: none;
    }
  }
`

interface FiltersContentProps {
  isOpen: boolean
}

export const FiltersContent = styled('div')<FiltersContentProps>`
  #close-icon {
    display: none;
  }
  & > div {
    overflow: hidden auto;
    overflow-wrap: break-word;
    padding: 30px;
  }
  @media (max-width: 600px) {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #333;
    left: ${({ isOpen }) => (isOpen ? '0' : '-400px')};
    box-sizing: border-box;
    top: 50px;
    bottom: 0;
    z-index: 4;
    transition: all 200ms ease-in-out;
    #close-icon {
      display: block;
      position: absolute;
      right: 10px;
    }
  }
`

export const ButtonContainer = styled('div')`
  #display-result-btn {
    display: none;
  }
  @media (max-width: 600px) {
    #display-result-btn {
      display: block;
    }
    position: initial;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    box-sizing: border-box;
  }
`
