import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import gfm from 'remark-gfm'
import { isEmpty } from 'lodash'
import { styled } from '@mui/material/styles'
import { Grid, Typography, Button, Divider, useMediaQuery } from '@mui/material'

import SEO from '../components/common/seo'
import { CustomMarkdown } from '../components/common/Styled'
import { routePaths } from '../constants/routes'
import { buildImageLink, formatEdges } from '../helpers'
import { ProductsListContainer } from '../components/ProductsList/Styled'
import ProductCard from '../components/ProductCard'
import { Product } from '../helpers/types'
import theme from '../theme'

export const Logo = styled('img')(({ theme }) => ({
  width: '50%',
  maxWidth: '300px',
  [theme.breakpoints.down('md')]: {
    margin: '0 0 30px',
    width: '80%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%'
  }
}))

const query = graphql`
  query {
    home: strapiHome {
      strapi_id
      subtitle
      description {
        data {
          description
        }
      }
      logo {
        strapi_id
        url
        alternativeText
      }
      referencing {
        metaTitle
        metaDescription
        metaImage {
          strapi_id
          url
          alternativeText
        }
        words {
          word
        }
      }
    }

    products: allStrapiProduct(
      sort: { fields: [publishedAt], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          strapi_id
          title
          price
          promotion
          sold
          images {
            strapi_id
            url
            alternativeText
          }
        }
      }
    }
  }
`

const IndexPage = () => {
  const { home, products } = useStaticQuery(query)
  const formattedProducts: Product[] = formatEdges(products)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { subtitle, logo, description, referencing } = home
  const { metaTitle, metaDescription, metaImage, words } = referencing

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={buildImageLink(metaImage.url)}
        pathname={routePaths.home}
        keywords={words}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        mb="25px"
        direction="column"
      >
        {logo && (
          <Logo src={buildImageLink(logo.url)} alt={logo.alternativeText} />
        )}
        {subtitle && (
          <Typography mb={5} variant="h2" textAlign="center">
            {subtitle}
          </Typography>
        )}
        {description?.data && (
          <Typography
            variant="h6"
            component="div"
            sx={{ maxWidth: '800px' }}
            textAlign="center"
          >
            <CustomMarkdown
              remarkPlugins={[gfm]}
              transformImageUri={buildImageLink}
              index
            >
              {description?.data?.description}
            </CustomMarkdown>
          </Typography>
        )}
        {!isEmpty(formattedProducts) && (
          <>
            <Divider
              sx={{ width: '250px', color: 'secondary', margin: '20px 0' }}
            />
            <Typography variant="h2" align="center">
              Les derniers produits
            </Typography>
            <ProductsListContainer
              data-sal={isMobile ? '' : 'slide-up'}
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              {formattedProducts.map(product => (
                <ProductCard key={product.strapi_id} {...product} />
              ))}
            </ProductsListContainer>
            <Button
              onClick={() => navigate(routePaths.shop)}
              variant="contained"
              color="primary"
            >
              Voir plus
            </Button>
          </>
        )}
      </Grid>
    </>
  )
}

export default IndexPage
