import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

interface PriceProps {
  hasPromotion: boolean;
  isSold: boolean;
}

export const Price = styled(Typography)<PriceProps>`
  text-decoration: ${({ hasPromotion, isSold }) =>
    hasPromotion || isSold ? "line-through" : "none"};
`;

interface PromotionProps {
  isSold: boolean;
}

export const Promotion = styled(Typography)<PromotionProps>`
  text-decoration: ${({ isSold }) => (isSold ? "line-through" : "none")};
  font-weight: bold;
  font-size: 18px;
`;

export const Sold = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
`;
